import React, { ReactNode, createContext, memo, useContext } from 'react';
import { FIREBASE_CONFIG } from 'utils/env';
import { Firebase } from './service';

const firebase = new Firebase(FIREBASE_CONFIG);

const Context = createContext<Firebase>(firebase);

interface Props {
  children?: ReactNode;
}

export const FirebaseProvider = memo(function FirebaseProvider(props: Props) {
  const { children } = props;

  return <Context.Provider value={firebase}>{children}</Context.Provider>;
});

export function useFirebase() {
  const firebaseClient = useContext(Context);

  return firebaseClient;
}
