import { PatchedGapi, GapiConfig } from './types';

export class GApi {
  service = window.gapi as PatchedGapi;

  initiator: Promise<void>;

  loaded = false;

  constructor(config: GapiConfig) {
    this.initiator = this.init(config);
  }

  loadAuth2 = () => {
    return new Promise(resolve =>
      this.service.load('client:auth2', () => {
        resolve();
      })
    );
  };

  init = async (config: GapiConfig) => {
    if (this.loaded) {
      return;
    }

    try {
      await this.loadAuth2();
      await this.service.client.init(config);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('gapi init failed', error);
    }
  };

  signIn = async () => {
    if (this.service.auth2.getAuthInstance().isSignedIn.get()) {
      return;
    }

    await this.service.auth2.getAuthInstance().signIn();
  };

  getDocument = async (documentId: string) => {
    await this.initiator;
    await this.signIn();

    const response = await this.service.client.docs.documents.get({
      documentId,
    });

    if (!response.status || response.status !== 200) {
      throw new Error('Cant read document');
    }

    return response.result;
  };
}
