/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

interface FirebaseConfig {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

export class Firebase {
  service = firebase;

  auth: firebase.auth.Auth;

  database: firebase.database.Database;

  constructor(config: FirebaseConfig) {
    this.service.initializeApp(config);

    this.auth = this.service.auth();
    this.database = this.service.database();
  }
}
