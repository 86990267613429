export const IS_DEV = process.env.NODE_ENV !== 'production';

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const GAPI_CONFIG = {
  clientId: process.env.REACT_APP_GAPI_CLIENT_ID || '',
  apiKey: process.env.REACT_APP_GAPI_API_KEY || '',
  discoveryDocs: [process.env.REACT_APP_GAPI_DISCOVERY_DOC || ''],
  scope: process.env.REACT_APP_GAPI_SCOPES || '',
};
