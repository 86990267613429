import React, {
  ReactNode,
  createContext,
  memo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { GAPI_CONFIG } from 'utils/env';
import { GApi } from './service';
import { GDoc } from './types';

const gapi = new GApi({ ...GAPI_CONFIG });

const Context = createContext(gapi);

interface Props {
  children?: ReactNode;
}

export const GApiProvider = memo(function GApiProvider(props: Props) {
  const { children } = props;

  return <Context.Provider value={gapi}>{children}</Context.Provider>;
});

export function useGApi() {
  const gapiClient = useContext(Context);

  return gapiClient;
}

export function useGDoc(docId: string) {
  const gapiClient = useGApi();
  const [document, setDocument] = useState<GDoc>();

  const refreshDocument = useCallback(() => {
    setDocument(undefined);
    gapiClient
      .getDocument(docId)
      .then(loadedDocument => setDocument(loadedDocument));
  }, [docId, gapiClient]);

  useEffect(() => {
    refreshDocument();
  }, [docId]);

  return { document, refreshDocument };
}
