import React, { useCallback } from 'react';
import { parse as parseSearch } from 'query-string';
import { useLocation } from 'react-router-dom';
import { Summary } from 'components/Summary';
import { AppBackground } from 'components/AppBackground';
import { useGDoc } from 'providers/GApi';
import { parseGDoc } from './gDocParser';
import styles from './styles.module.scss';

export function SummaryPreview() {
  const { search } = useLocation();
  const { docId } = parseSearch(search);
  if (typeof docId !== 'string') {
    throw new Error('docId must be in URL');
  }

  const { document, refreshDocument } = useGDoc(docId);

  const onRefreshClick = useCallback(() => {
    refreshDocument();
  }, [refreshDocument]);

  if (!document) {
    return <span>Loading</span>;
  }

  return (
    <AppBackground>
      <div className={styles.SummaryPreview}>
        <button
          type="button"
          onClick={onRefreshClick}
          className={styles.RefreshButton}
        >
          Refresh
        </button>
        <Summary summary={parseGDoc(document)} />
      </div>
    </AppBackground>
  );
}
