import React, { ReactNode, Fragment } from 'react';

export function replaceNewLineWithBr(strWithNewLines: string) {
  const children: ReactNode[] = strWithNewLines.split('\n');

  return children.map((child, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        {child}
        <br />
      </Fragment>
    );
  });
}
