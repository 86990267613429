import React from 'react';
import BookInfo from './BookInfo';
import Page from './Page';
import { SummaryDocument } from './types';
import styles from './styles.module.scss';

interface SummaryProps {
  summary: SummaryDocument;
}

export function Summary(props: SummaryProps) {
  const { summary } = props;

  return (
    <div className={styles.Summary}>
      <div className={styles.SummaryOverview}>
        <div className={styles.Sticky}>
          <BookInfo
            title={summary.title}
            author={summary.author}
            keywords={summary.keywords}
            overview={summary.overview}
          />
        </div>
      </div>
      <div className={styles.SummaryPages}>
        {summary.pages.map(({ title, content }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Page key={index} title={title} content={content} />
        ))}
      </div>
    </div>
  );
}
