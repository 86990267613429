import {
  GDoc,
  Contentful,
  Content,
  TableRow,
  Table,
  TableCell,
  Paragraph,
  Lists,
} from 'providers/GApi/types';
import { PageContentItem } from 'components/Summary/types';

const KEYWORDS_SEPARATOR = ',';
const DOTTED_LIST_GLYPH = '•';
const NUMBERED_LIST_GLYPH_TYPE = 'DECIMAL';

const CONTENT_TYPES = {
  TEXT: 'TEXT',
  INSIGHT: 'INSIGHT',
  QUOTE: 'QUOTE',
  NUMBERED_LIST: 'NUMBERED_LIST',
  DOTTED_LIST: 'DOTTED_LIST',
  DOTTED_LIST_ITEM: 'DOTTED_LIST_ITEM',
  NUMBERED_LIST_ITEM: 'NUMBERED_LIST_ITEM',
};

const ELEMENT_TYPES = {
  TEXT_RUN: 'textRun',
  TABLE: 'table',
  TABLE_ROWS: 'tableRows',
  TABLE_CELLS: 'tableCells',
  PARAGRAPH: 'paragraph',
  CONTENT: 'content',
};

type ExtractElement =
  | Contentful
  | Content
  | Table
  | TableRow
  | TableCell
  | Paragraph;

function extractTextFromElement(element: ExtractElement, extractedText = '') {
  const keys = Object.keys(element);
  const text: string = keys.reduce((textFromElement, elementKey) => {
    switch (elementKey) {
      case ELEMENT_TYPES.TEXT_RUN: {
        return textFromElement + (element as Content).textRun?.content;
      }

      case ELEMENT_TYPES.PARAGRAPH: {
        return (
          (element as Content).paragraph?.elements.reduce(
            (textFromContentItem, contentItem) =>
              extractTextFromElement(contentItem, textFromContentItem),
            textFromElement
          ) ?? textFromElement
        );
      }

      case ELEMENT_TYPES.CONTENT: {
        return (element as Contentful).content.reduce(
          (textFromContentItem, contentItem) =>
            extractTextFromElement(contentItem, textFromContentItem),
          textFromElement
        );
      }

      case ELEMENT_TYPES.TABLE_ROWS: {
        return (element as Table).tableRows.reduce(
          (textFromRow, tableRow) =>
            extractTextFromElement(tableRow, textFromRow),
          textFromElement
        );
      }

      case ELEMENT_TYPES.TABLE_CELLS: {
        return (element as TableRow).tableCells.reduce(
          (textFromCells, tableCell) =>
            extractTextFromElement(tableCell, textFromCells),
          textFromElement
        );
      }
      default: {
        return textFromElement;
      }
    }
  }, '');
  return `${extractedText} ${text}`;
}

function createFailedToFind(elementName: string) {
  return `Failed to find ${elementName};`;
}

function findOverviewTable(document: GDoc) {
  const { content } = document.body;

  return content.find(element => element.table?.rows === 4)?.table;
}

function findDocumentTitle(document: GDoc) {
  const overviewTable = findOverviewTable(document);

  if (!overviewTable) {
    return createFailedToFind('Book title');
  }

  return extractTextFromElement(overviewTable.tableRows[0]).trim();
}

function findDocumentAuthor(document: GDoc) {
  const overviewTable = findOverviewTable(document);

  if (!overviewTable) {
    return createFailedToFind('Book author');
  }

  return extractTextFromElement(overviewTable.tableRows[1]).trim();
}

function findDocumentKeywords(document: GDoc) {
  const overviewTable = findOverviewTable(document);

  if (!overviewTable) {
    return [createFailedToFind('Book keywords')];
  }

  return extractTextFromElement(overviewTable.tableRows[2])
    .split(KEYWORDS_SEPARATOR)
    .map(keyword => keyword.trim());
}

function findDocumentOverview(document: GDoc) {
  const overviewTable = findOverviewTable(document);

  if (!overviewTable) {
    return createFailedToFind('Book overview');
  }

  return extractTextFromElement(overviewTable.tableRows[3]).trim();
}

function parseInsight(table: Table) {
  return {
    type: CONTENT_TYPES.INSIGHT,
    content: extractTextFromElement(table.tableRows[0].tableCells[1]).trim(),
  };
}

function parseQuote(table: Table) {
  const quote = extractTextFromElement(table.tableRows[0].tableCells[1]).trim();

  const quoteAuthor = extractTextFromElement(
    table.tableRows[1].tableCells[1]
  ).trim();

  return {
    type: CONTENT_TYPES.QUOTE,
    content: `"${quote}" ~ ${quoteAuthor}`,
  };
}

function parseText(contentItem: Content) {
  return {
    type: CONTENT_TYPES.TEXT,
    content: extractTextFromElement(contentItem).trim(),
  };
}

function parseNumberedListItem(contentItem: Content) {
  return {
    type: CONTENT_TYPES.NUMBERED_LIST_ITEM,
    content: extractTextFromElement(contentItem).trim(),
  };
}

function parseDotedListItem(contentItem: Content) {
  return {
    type: CONTENT_TYPES.DOTTED_LIST_ITEM,
    content: extractTextFromElement(contentItem).trim(),
  };
}

function parseContent(documentContent: Content[], lists: Lists) {
  return documentContent
    .map(contentItem => {
      const isInsight =
        contentItem.table?.columns === 2 && contentItem.table?.rows === 1;
      if (isInsight) {
        const table = contentItem.table as Table;

        return parseInsight(table);
      }

      const isQuote =
        contentItem.table?.columns === 2 && contentItem.table?.rows === 2;
      if (isQuote) {
        const table = contentItem.table as Table;
        return parseQuote(table);
      }

      if (contentItem.paragraph?.bullet) {
        const list = lists[contentItem.paragraph.bullet.listId];
        const { glyphType } = list.listProperties.nestingLevels[0];

        if (glyphType === NUMBERED_LIST_GLYPH_TYPE) {
          return parseNumberedListItem(contentItem);
        }

        return parseDotedListItem(contentItem);
      }

      return parseText(contentItem);
    })
    .filter(({ content }) => content.length > 0);
}

type PageContentWithList = Array<{
  type: string;
  content?: string;
  list?: string[];
}>;

function mergeListsInContent(content: PageContentWithList) {
  const mergedLists = content.reduce<PageContentWithList>((result, item) => {
    const lastItem = result[result.length - 1];

    if (item.type === CONTENT_TYPES.DOTTED_LIST_ITEM) {
      const isLastItemDottedList = lastItem.type === CONTENT_TYPES.DOTTED_LIST;

      if (!isLastItemDottedList) {
        return [
          ...result,
          {
            type: CONTENT_TYPES.DOTTED_LIST,
            list: item.content ? [item.content] : [],
          },
        ];
      }

      if (Array.isArray(lastItem.list) && item.content) {
        lastItem.list.push(item.content);
      }

      return result;
    }

    if (item.type === CONTENT_TYPES.NUMBERED_LIST_ITEM) {
      const isLastItemNumberedList =
        result[result.length - 1].type === CONTENT_TYPES.NUMBERED_LIST;

      if (!isLastItemNumberedList) {
        return [
          ...result,
          {
            type: CONTENT_TYPES.NUMBERED_LIST,
            list: item.content ? [item.content] : [],
          },
        ];
      }

      if (Array.isArray(lastItem.list) && item.content) {
        lastItem.list.push(item.content);
      }

      return result;
    }

    return [...result, item];
  }, []);

  return mergedLists.map<PageContentItem>(element => {
    if (
      element.type === CONTENT_TYPES.DOTTED_LIST &&
      Array.isArray(element.list)
    ) {
      return {
        type: CONTENT_TYPES.TEXT,
        content: element.list
          .map(listItem => `${DOTTED_LIST_GLYPH} ${listItem}`)
          .join('\n'),
      };
    }

    if (
      element.type === CONTENT_TYPES.NUMBERED_LIST &&
      Array.isArray(element.list)
    ) {
      return {
        type: CONTENT_TYPES.TEXT,
        content: element.list
          .map((listItem, index) => `${index + 1}. ${listItem}`)
          .join('\n'),
      };
    }

    return {
      type: element.type,
      content: element.content ?? '',
    };
  });
}

function findDocumentPages(document: GDoc) {
  const { content } = document.body;

  return content
    .filter(element => element.table?.rows === 2)
    .map(element => element.table as Table)
    .map(pageTable => ({
      title: pageTable?.tableRows[0]
        ? extractTextFromElement(pageTable.tableRows[0]).trim()
        : createFailedToFind('Page title'),
      content: mergeListsInContent(
        parseContent(
          pageTable.tableRows[1].tableCells[0].content,
          document.lists
        )
      ),
    }));
}

export function parseGDoc(document: GDoc) {
  return {
    title: findDocumentTitle(document),
    author: findDocumentAuthor(document),
    keywords: findDocumentKeywords(document),
    overview: findDocumentOverview(document),
    pages: findDocumentPages(document),
  };
}
