import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { FirebaseProvider } from 'providers/Firebase';
import { GApiProvider } from 'providers/GApi';
import { UserProvider } from 'providers/User';
import { Routes } from 'routes';
import 'styles/global.scss';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <FirebaseProvider>
          <GApiProvider>
            <UserProvider>
              <Routes />
            </UserProvider>
          </GApiProvider>
        </FirebaseProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
