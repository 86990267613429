import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  color?: 'light';
}

export function AppBackground(props: Props) {
  const { children, color = 'light' } = props;

  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
        <html className={styles[`AppBackground--${color}`]} />
      </Helmet>
      {children}
    </>
  );
}
