export function createAction<T = string, P = undefined, E = undefined>(
  actionType: T
) {
  const action = (payload: P, error?: E) => ({
    type: actionType,
    payload,
    error,
  });

  action.valueOf = () => actionType;
  action.type = actionType;

  return action;
}
