import React, {
  ReactNode,
  createContext,
  memo,
  useContext,
  useReducer,
  Reducer,
  Dispatch,
} from 'react';
import { createAction } from 'utils/actions';

interface Props {
  children?: ReactNode;
}

interface State {
  accessToken?: string;
  refreshToken?: string;
}

export const setTokensAction = createAction<
  'Users/SET_TOKENS',
  {
    accessToken?: string;
    refreshToken?: string;
  }
>('Users/SET_TOKENS');

export const removeTokens = createAction<'Users/REMOVE_TOKENS'>(
  'Users/REMOVE_TOKENS'
);

type Action =
  | ReturnType<typeof setTokensAction>
  | ReturnType<typeof removeTokens>;
type UserReducer = Reducer<State, Action>;

const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
};

const userReducer: UserReducer = (state, action) => {
  switch (action.type) {
    case setTokensAction.type: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    }

    default: {
      return state;
    }
  }
};

const Context = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => {},
]);

export const UserProvider = memo(function UserProvider(props: Props) {
  const { children } = props;

  const user = useReducer(userReducer, initialState);

  return <Context.Provider value={user}>{children}</Context.Provider>;
});

export function useUserContext() {
  const context = useContext(Context);

  return context;
}
