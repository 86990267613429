import React from 'react';
import { BookInfo } from './types';
import { replaceNewLineWithBr } from './utils';
import styles from './styles.module.scss';

export default function BookInfoComponent(props: BookInfo) {
  const { title, author, keywords, overview } = props;

  return (
    <div className={styles.BookInfo}>
      <h1 className={styles.BookTitle}>{replaceNewLineWithBr(title)}</h1>
      <h2 className={styles.BookAuthor}>{replaceNewLineWithBr(author)}</h2>
      <ul className={styles.BookKeywordsList}>
        {keywords.map((keyword, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className={styles.BookKeywordsItem}>
            {replaceNewLineWithBr(keyword)}
          </li>
        ))}
      </ul>
      <h3 className={styles.DescriptionTitle}>Description</h3>
      <p className={styles.Description}>{replaceNewLineWithBr(overview)}</p>
    </div>
  );
}
